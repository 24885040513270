<template>
  <div id="login">
    <div class="content">
      <div class="content-form">
        <div class="header-modal">
          <slot name="header">
            <h2 class="merriweather-reg-26">M’inscrire</h2>
          </slot>
          <div class="login">
            <p class="raleway-reg-p15 mr-4">Vous avez déjà un compte ?</p>
            <a class="link-hortense" href="/connexion">Me connecter</a>
          </div>
        </div>
        <div class="form-signup raleway-reg-p15">
          <div class="form-item">
            <label for="last_name">Nom*</label>
            <input v-model="userInfo.last_name" type="text" />
          </div>
          <div class="form-item">
            <label for="first_name">Prénom*</label>
            <input v-model="userInfo.first_name" type="text" />
          </div>
          <div class="form-item">
            <label for="birthday">Date de naissance</label>
            <input
              v-model="userInfo.birthday"
              type="date"
              data-date-inline-picker="true"
            />
          </div>
          <div class="form-item">
            <label for="email">Email*</label>
            <input v-model="userInfo.email" type="text" />
          </div>

          <div class="form-item">
            <label for="password"
              >Mot de passe* <br />12 caractères minimum</label
            >
            <input v-model="userInfo.password" type="password" />
          </div>
          <div class="form-item">
            <label for="password_confirmation"
              >Confirmation du mot de passe*</label
            >
            <input
              v-model="userInfo.password_confirmation"
              type="password"
            />
          </div>
        </div>
        <div class="form-check raleway-reg-p15">
          <div class="form-item newsletter">
            <input
              type="checkbox"
              id="newsletter"
              value="newsletter"
              v-model="checkedItems"
            />
            <label for="newsletter"
              >J’accepte de recevoir les newsletters d’Hortense et
              j’accepte la
              <a
                :href="'/pages/privacy_policy/'"
                class="link-hortense"
                target="_blank"
                >politique de confidentialité</a
              >.</label
            >
          </div>
          <div class="form-item privilege">
            <input
              type="checkbox"
              id="privilege"
              value="privilege"
              v-model="checkedItems"
            />
            <label for="privilege" @click="createPrivilege"
              >Je m’inscris au programme de fidélité</label
            >
          </div>
          <div class="form-item charte">
            <input
              type="checkbox"
              id="charte"
              value="charte"
              v-model="checkedItems"
            />
            <label for="charte"
              >Je m’engage à respecter la
              <a
                :href="'/pages/ethic/'"
                class="link-hortense"
                target="_blank"
                >charte d’engagements « Tourisme Durable »</a
              ></label
            >
          </div>
          <div class="form-item perso_info">
            <input
              type="checkbox"
              id="perso_info"
              value="perso_info"
              v-model="checkedItems"
            />
            <label for="perso_info"
              >J’accepte que mes informations soient utilisées, uniquement
              dans le cadre de ma demande et dans le cadre de la relation
              commerciale responsable, éthique et personnalisée qui peut
              en découler.</label
            >
          </div>
          <div>
            <p style="color: red">{{ this.text }}</p>
          </div>
          <button @click="UserBasicInfo" class="hortense-btn-primary">
            M'inscrire
          </button>
        </div>
      </div>
      <div class="social-connection">
        <p class="raleway-reg-p15">ou</p>
        <p class="raleway-semi-p15">S'inscrire avec :</p>
      </div>
      <div class="social-connection-btn">
        <div class="social-btn">
          <img
            src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/google.png"
            alt="google"
          />
          <a href="/users/auth/google_oauth2">Google</a>
        </div>
        <div class="social-btn">
          <img
            src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/facebook.png"
            alt="facebook"
          />
          <a href="/users/auth/facebook">Facebook</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetcher from "packs/shared/api.js";

export default {
  name: "SignUpForm",
  props: {
    redirectUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      userInfo: {
        first_name: "",
        last_name: "",
        birthday: "",
        email: "",
        password: "",
        password_confirmation: "",
        newsletter: false,
      },
      checkedItems: [],
      showModal: false,
      showModallogin: this.showModalLogin,
      status: null,
      text: null,
    };
  },
  methods: {
    formatDate(date) {
      const set = new Date(date);
      let getDate = set.getDate().toString();
      if (getDate.length === 1) {
        getDate = "0" + getDate;
      }
      let getMonth = (set.getMonth() + 1).toString();
      if (getMonth.length === 1) {
        getMonth = "0" + getMonth;
      }
      let getYear = set.getFullYear().toString();
      //today
      return getYear + "-" + getMonth + "-" + getDate;
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async UserBasicInfo() {
      if (!this.validateEmail(this.userInfo.email)) {
        this.text = "Email incorrect";
        return;
      }
      if (this.userInfo.password.length < 12) {
        this.text = "Le mot de passe doit contenir 12 caractères minimum ";
        return;
      }
      if (this.userInfo.password !== this.userInfo.password_confirmation) {
        this.text = "Le mot de passe saisi doit être identique";
        return;
      }

      if (this.checkedItems.includes("newsletter")) {
        this.userInfo.newsletter = true;
      }

      try {
        const response = await fetcher.post("/users/", {
          user: {
            first_name: this.userInfo.first_name,
            last_name: this.userInfo.last_name,
            birthday: this.formatDate(this.userInfo.birthday),
            email: this.userInfo.email,
            password: this.userInfo.password,
            password_confirmation: this.userInfo.password_confirmation,
            newsletter: this.userInfo.newsletter,
          },
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'sign_up',
          method: 'email_password',
        });
        window.location.replace(this.redirectUrl)
      } catch (e) {
        console.log(e);
        this.text = "Cet email est déjà utilisé";
      }
    },
    async createPrivilege() {
      try {
        const response = await fetcher.post("/privileges");
      } catch (e) {
        console.error(e);
      }
    },
    setUser(user) {
      this.user = user;
      this.showModal = false;
      this.showModallogin = false;
      this.$emit("close", user);
    },
  },
};
</script>

<style lang="scss" scoped>
.link-hortense {
  cursor: pointer;
}
.modal-backdrop {
  position: fixed;
  top: 0;

  left: 0;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 721px;
  height: fit-content;
  margin: 51px auto;
  padding: 1rem 1rem;
  transition: all 0.3s ease;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>

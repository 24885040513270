<template>
  <div id="banner">
    <video autoplay loop muted class="home_video">
      <source src="https://hortense-vue.s3.eu-west-3.amazonaws.com/videos/Hortense-banner-video.mp4">
      <!--source src="https://hortense-vue.s3.eu-west-3.amazonaws.com/videos/Hortense-banner-video-r.webm"-->
    </video>
    <div class=banner-container>
      <div class="banner-text">
        <h1 class="banner-text--title"> {{ $t("Banner.header") }} </h1>
        <p class="banner-text--para">{{ $t("Banner.subtitle") }}</p>
      </div>
      <div class="banner-search">
        <input class="search-bar" type="text" :placeholder="$t('Banner.whereto')" v-model="search"></input>
        <a class="around-btn link-hortense" @click="getLocation">
          <img src="../../images/pin blanc.svg" alt="">
          Autour de moi
        </a>

        <a class="no-deco" @click.prevent="handleSearch">
          <div class="search-button">
            {{ $t("search") }}
          </div>
        </a>
      </div>

    </div>
    <div class="logo-see-more" @mouseover="animLogo" @click="scrollDown"><img src="../../images/fleche blanc.svg" alt="fleche scroll bas"></div>
  </div>
</template>

<script>

import fetcher from 'packs/shared/api.js';
import autocomplete from 'js-autocomplete';

export default {
  name: "banner",
  template: '<Banner/>',
  mode: 'production',
  components: {
    autocomplete
  },
  props: {
    destinations: Array
  },
  data() {
    return {
      search: "",
      lodgingsName: [],
      regionsName: [],
      destinationSearch: [],
    }
  },
  methods: {
    getLocation(){
      navigator.geolocation.getCurrentPosition((position) => {
        window.open(`/reservation-hotel?geo=${position.coords.latitude},${position.coords.longitude}`, '_blank');
        // Turbolinks.visit(`/reservation-hotel?geo=${position.coords.latitude},${position.coords.longitude}`)
      });
    },
    animLogo(e) {
      e.currentTarget.animate([
            {transform: 'translateY(0px)'},
            {transform: 'translateY(-10px)'},
            {transform: 'translateY(0px)'}
          ],
          {
            duration: 800,
            easing: 'ease-in-out',
            iterations: 2
          })
    },
    handleSearch() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'search',
        ecommerce: {
          search_term: this.search,
          // booking_window: bookingWindow,
          date_in: '',
          date_out: '',
          number_of_people: '',
          number_of_adult: '',
          number_of_child: '',
          result: 144,
          number_of_nights: 1
        }
      });
      window.location.href = `/reservation-hotel?search=${this.search}`;
    },
    scrollDown() {
      this.$el.nextElementSibling.scrollIntoView({behavior: 'smooth'});
    },
  },
  computed: {
    formatDesti() {
      const countries = this.destinations.map(i => i['name']);
      this.destinations.forEach((country)=>{
        this.regionsName.push(country.regions.map(i => i['name']));
      });
      this.destinations.forEach((country)=>{
        this.regionsName.push(country.cities.map(i => i['name']));
      });
      this.destinations.forEach((country)=>{
        this.regionsName.push(country.departments.map(i => i['name']));
      });
      this.regionsName.push(countries);
      this.destinationSearch = this.regionsName.flat();
      return this.destinationSearch;
    },
  },
}
</script>

<style lang="scss" scope='app/assets/stylesheets/components/banner'>
</style>

<template>
  <div id="cardProduct-big-two" v-if="this.lodging">
    <div class="hub-img-zoom" @click="goToLodging()" v-if="this.lodging.photo_banner">
      <div class="sticker-hub d-flex mt-4 pl-2">
        <img :src="greenNotePicto((this.lodging.score).toFixed())" :alt="(this.lodging.score).toFixed()" v-if="this.lodging.score">
        <div class="sticker-info d-flex" v-if="this.lodging.score">
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2"> <b>{{ (this.lodging.score).toFixed() }}</b>/10</p>
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2">Green note</p>
        </div>
      </div>
      <img :src="formatSource()" alt="Lodging Image" class="hub-img" :alt="this.lodging.name">
      <!-- <img class="hub-img" :src="formatSource()" :alt="this.lodging.name" v-if='this.photo_keys'> -->
    </div>
    <div class="card-hub-info" @click="goToLodging()">
      <div class="hub-header d-flex">
        <h1 class="raleway-bold-h3">{{this.lodging.name}}</h1>
        <div class="like-icon">
        </div>
      </div>
      <div class="standing">
        <img src="../../images/Etoiles.svg" alt="Etoile remplie" v-if="parseInt(lodging.standing)" v-for="i in parseInt(lodging.standing)" :key="i" />
        <img src="../../images/blanc.png" alt="white" />
      </div>
      <div class="reviews-btn">
        <div class="hub-reviews d-flex">
          <div>
            <div class="score-hub d-flex">
            </div>
            <p class="raleway-reg-p13" style="margin-bottom: 0rem;" v-if='this.lodging'>{{formatStyle(this.lodging.country.name)}}, {{this.lodging.region.name}}</p>
            <p class="raleway-reg-p13" style="margin-bottom: 0rem;">
              A partir de <strong>{{ this.lodging.lowest_price}}€/nuit</strong>
            </p>
          </div>
        </div>
        <a @click="openInNewWindow(lodging.slug, $event)"  class="xs-btn-primary text-center" >Voir les disponibilités</a>

      </div>
    </div>
  </div>
</template>

<script>
import fetcher from 'packs/shared/api.js';
import Mapfind from 'components/Mapfind.vue';

export default{
  name: 'CardProductHome',
  props: {
    lodging: Object,
    user: Object,
    wishlist: Object
  },
  components: { Mapfind },
  data() {
    return {
      photo_keys: this.lodging.photos_keys,
      uid: this.user.id,
      lid: this.lodging.id,
      showMap: false,
      markers: [],
      ratedLodgings: [],
      averageList:[],
      average: 0,
      wishlistLodgings: null,
    }
  },
  methods: {
    greenNotePicto(score){
      if (score === `${score}`){
        return require(`../../images/greennote ${score}.svg`)
      } else {
        return require("../../images/blanc.png")
      }
    },
    openInNewWindow(slug, event) {
      event.preventDefault();
      window.open('/reservation-hotel/' + slug, '_blank');
    },
    goToLodging(){
      window.open('/reservation-hotel/' + this.lodging.slug, '_blank');
    },
    markerMap() {
      return [this.lodging.longitude, this.lodging.latitude];
    },
    displayMap() {
      this.showMap = !this.showMap;
      this.markers = this.markerMap();
    },
    formatStyle(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    formatSource() {
      if (this.lodging.photo_banner) {
        const baseUrl = 'https://www.hortense.green/';
        return `${baseUrl}${this.lodging.photo_banner}`
      } else {
        return "https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0="
      }
    },
    async toggleLike() {
      try {
        if (Object.entries(this.user).length !== 0) {
          const response = await fetcher.patch('/wishlists/'+ this.user.id, { lodging_id: this.lodging.id });
          this.wishlistLodgings = response.data.lodgings;
        } else {
          this.$root.$emit('openModal');
          window.scrollTo({
            top: 0,
            behaviour: 'smooth'
          });
        }
      } catch(e) {
        console.error(e)
      }
    },
    ratedLodging(){
      this.ratedLodgings = this.lodging.reservations.filter(el => el.rated === true)
      return this.ratedLodgings
    },
    averageRate(){
      this.ratedLodgings.forEach((el) => {
        this.averageList.push(el.rate_average)
      })
      return this.averageList
    },
    averageLodging(){
      if(this.averageList.length !== 0){
        this.average = (this.averageList.reduce((a, b) => a + b, 0) / this.averageList.length).toFixed(1);
        return this.average
      } else {
        return this.average = '-'
      }
    },
    isIncludeInWishlist() {
      if (this.wishlistLodgings) {
        for (const lodging of this.wishlistLodgings) {
          if (lodging.id === this.lodging.id) {
            return true
          }
        }
      }
      return false
    },
  },
  mounted(){
    this.ratedLodging();
    this.averageRate();
    this.averageLodging();
  },
  computed: {
    defineWishlistLodgings() {
      return this.wishlistLodgings = this.wishlist.lodgings
    }
  }
}
</script>

<style>
#cardProduct-big-two{
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
  .card-hub-info{
    width: 100%;
  }
  .hub-img{
    width: 106%;
    height: 211px;
    padding-right: 1rem;
  }
  .hub-header{
    margin-top: 1rem;
    justify-content: space-between;
  }
  .location-hub{
    display: none;
  }
  .catch-hub-phone{
    display: block;
  }
  .catch-hub{
    display: none;
  }
  .reviews-btn{
    display: flex;
    justify-content: space-between;
  }
  .hub-img-zoom{
    margin-top: auto;
    margin-bottom: auto;
    width: 300px;
    height: 224px;
    margin-right: 1rem;
    overflow: hidden;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .sticker-hub{
    position: absolute;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;

    img{
      width: 19px;
      height: 28px;
    }
  }
  .sticker-info{
    flex-direction: column;
  }

  .standing{
    img{
      width: 15px;
    }
  }
  .reviews-btn{
    display: flex;
    justify-content: space-between;
    .xs-btn-primary{
      width: 120px;
      height: 49px;
    }
  }
  .hub-reviews{
    img{
      width: 23px;
      height: 27px;
    }
    .review-info{
      flex-direction: column;
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #cardProduct-big{
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 0 auto;
    .card-hub-info{
      width: 100%;
    }
    .hub-img{
      width: 100%;
      height: 211px;
      padding-right: 1rem;
    }
    .hub-header{
      margin-top: 1rem;
      justify-content: space-between;
    }
    .location-hub{
      display: none;
    }
    .catch-hub-phone{
      display: block;
    }
    .catch-hub{
      display: none;
    }
    .reviews-btn{
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>


<template>
  <div id="card-promo-code">
    <div class="head-card">
      <p class="raleway-reg-p24">Vos codes exclusifs</p>
      <p class="raleway-reg-p15">Vous avez remporté un code promo suite à un concours Hortense sur nos réseaux sociaux ? Vos codes sont disponibles juste ici. N’oubliez pas de les utiliser avant la date de validité.</p>
    </div>
    <div class="green-section mt-4" v-if='this.promoCodes > 0'>
      <template v-for="promoCode in this.promoCodes">
        <div class="green-night mb-3">
          <p class="raleway-bold-p mb-0">BIENVENUE</p>
          <p class="raleway-reg-p15 mb-0">- 10% sur votre premier séjour</p>
          <p class="raleway-reg-p15 mb-0">no validity date</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'card-promo-code',
    props: ["user"],
    data() {
      return {
        userStatut: '',
        userMount: '',
        promoCodes: 1,
      }
    },
    methods: {
      async setUserLevel(){
        const res = await fetcher.get('/dashboard/'+this.user.slug)
        this.userMount = res.data
        this.userStatut = this.userMount.privilege.statut
      },
    },
    mounted() {
      this.setUserLevel();
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
